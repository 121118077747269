/** Styles form _tables.less of theme blank are moved here.
   * For some tables there is mobile layout applied also for tablet resolutions
   * Variables for typograpy, colors, border etc. are added
*/

@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/visually-hidden';
@import 'components/typography/mixin';
@import 'components/table/mixin';

$table_background: transparent !default;
$table_border-bottom: 1px solid $color_border-500 !default;

$table_row-border: 1px solid $color_border-500 !default;
$table_column-border: none !default;
$table_even-row-background: transparent !default;

$table_head-border-bottom: 2px solid $color_border-500 !default;
$table_head-color: $color_text-800 !default;
$table_head-font-size: $font-size_headline-5 !default;
$table_head-text-transform: uppercase !default;
$table_head_font-size--mobile: 1.4rem !default;
$table_head_move-on-mobile: true !default;

$table_item-color: $color_text-800 !default;
$table_item-font-size: $font-size_paragraph !default;
$table_item-font-weight: 400 !default;
$table_item-line-height: 155%;
$table_item-padding: 0.8rem 1rem !default;
$table_actions-links-separator: 2px solid $color_border-500 !default;

/* stylelint-disable no-descending-specificity */

.table-wrapper {
    margin-bottom: 2rem;

    @include media('<tablet') {
        border: none;
        display: block;
    }

    &.cart {
        @include media('>=tablet') {
            .table-wrapper.cart & {
                border-top: none;
            }
        }
    }

    &.grouped {
        tbody:last-child {
            td {
                border-bottom: none;
            }
        }
    }
}

.table {
    // border-bottom: $table_border-bottom;
    max-width: 100%;
    width: 100%;
    background: $table_background;

    & > caption {
        @include visually-hidden();
    }

    &:not(.totals):not(.table-totals) {
        border: none;

        td {
            border: none;
            padding: $table_item-padding;
        }

        thead {
            border-bottom: $table_head-border-bottom;
            background: $color_cerulean-blue;
            th {
                font-size: $table_head-font-size;
                // text-transform: $table_head-text-transform;
                // color: $table_head-color;
                color: $color_white;	
                text-align: center;	
                font-weight: 400;
            }
        }

        tbody {
            .rows {	
                height: 72px;	
                .wartosc {	
                    font-weight: 600;	
                    &:after {	
                        content: ' zł';	
                    }
                }	
            }
            td {
                font-size: $table_item-font-size;
                color: $table_item-color;
                font-weight: $table_item-font-weight;
                // border-bottom: $table_row-border;
                border-left: $table_column-border;

                &.actions {
                    @include media('<tablet') {
                        border-bottom: $table_row-border;
                    }

                    a + a:before {
                        margin: 0 0.7rem 0 0.5rem;
                        content: '';
                        display: inline-block;
                        width: 1px;
                        height: $table_item-font-size;
                        border-right: $table_actions-links-separator;
                        vertical-align: middle;
                    }
                }

                &:first-child {
                    border-left: none;
                }
            }

            tr:first-child td {
                .account & {
                    @include media('<laptop') {
                        padding-top: 0;
                    }
                }
            }

            tr {
                &:nth-child(even) {
                    background: $table_even-row-background;
                }
            }
        }

        tfoot {
            font-size: $table_item-font-size;

            @include media('<tablet') {
                background: $color_gallery;
            }

            .mark {
                text-align: right;
            }

            @include media('<tablet') {
                .amount {
                    text-align: right;
                }
            }
        }
    }

    .price-excluding-tax,
    .price-including-tax,
    .items-qty,
    .product-item-name {
        font-size: $table_item-font-size;
        line-height: $table_item-line-height;
    }
}

//  Mobile layout
@include media('<tablet') {
    .table-wrapper {
        @include table-wrapper-mobile-layout();

        .table {
            &:not(.comparison) {
                &:not(.totals):not(.table-totals) {
                    @include table-mobile-layout(
                        $head_color: $table_head-color,
                        $head_move-on-mobile: $table_head_move-on-mobile,
                        $head_font-size--mobile: $table_head_font-size--mobile
                    );

                    @if ($table_head_move-on-mobile) {
                        &.additional-attributes {
                            tbody {
                                th {
                                    display: none;
                                }

                                td {
                                    &:last-child {
                                        border: none;
                                        padding: 0 0 0.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .data-table-definition-list {
        thead {
            display: none;
        }

        tbody th {
            padding-bottom: 0;
        }

        tbody th,
        tbody td {
            display: block;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

// Some tables display mobile layout also on tablet resolution
.table-wrapper.cart,
.account .table-wrapper {
    @include table-wrapper-mobile-layout();

    .table {
        @include media('>=tablet', '<laptop') {
            @include table-mobile-layout();
        }
    }
}

/* stylelint-enable */

.price-and-cart {
    display: flex;
    #validation-message-box {
        flex-grow: 2;
    }
    .totality-price {
        flex-grow: 1;
        p {
            text-align: center;
            font-size: 22px;
            span {
                &:nth-child(1) {
                    font-weight: 300;
                }
                &:nth-child(2) {
                    font-weight: 600!important;
                }
            }
            &:nth-child(2) {
                font-size: 18px!important;
            }
        }   
    }
}

.table.data.grouped {
    margin-top: 1rem;
    border-bottom: 1px solid #d1d1d1;
    thead {
        display: table-header-group !important;
    }
    .tbody {
        .promo {
            text-decoration: line-through;
        }
        .col.item {
            display: table-cell;
            &::before {
                display: none;
            }
        }
    }
    .col {
        min-width: 100px;
        text-align: center;
        vertical-align: middle;
        margin: 5px 0;
        padding: 15px 0;
    }
    .col-full-width {
        &:before {
            display: none !important;
        }
    }
    tbody td {
        display: table-cell;
        &:before {
            display: none;
        }
        tr {
            display: flex;
            @include media('>tablet') {
                display: table-row;
            }
        }
        td {
            padding-top: 0 !important;
            @include media('>tablet') {
                padding-top: 8px !important;
            }
            &:first-letter {
                text-transform: uppercase;
            }
            &:before {
                display: none !important;
            }
        }
    }
    .col-full-width {
        tr {
            td {
                margin-left: 10px;
            }
        }
        #customers {
            div {
                padding: 5px 10px;
                font-size: 16px;
                span:first-letter {
                    text-transform: uppercase;
                }
            }
            div:nth-child(even) {
                background-color: $color_gallery;
            }
        }
        .town {
            width: 25%;
            display: inline-block;
        }
    }
    .click-me {
        .fa-chevron-down.active {
            transform: rotate(-180deg);
        }
    }
    .stock.unavailable {
        .out-of-stock {
            font-size: 20px;
        }
        a {
            font-size: 41px;
        }
    }
    .fa-chevron-down {
        margin-left: 5px;
    }
}
.rows {
    .col.item.sztuka.ecommerce-tuplex {
        .brutto {
            font-size: 14px;
        }
    }
    .col.item.cena {
        display: flex !important;
        flex-direction: column-reverse;
    }
}