/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* Radio and checkbox styling */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-reviews {
  flex: 1 1 0; }
  @media (max-width: 63.99em) {
    .cs-reviews {
      border-bottom: 1px solid #dcdcdc; } }
  .cs-reviews__title {
    background: #f0f0f0; }
    @media (min-width: 48em) {
      .cs-reviews__title {
        background: none;
        padding: 0; } }
  .cs-reviews__list {
    list-style: none;
    margin: 0;
    padding: 0; }
  .cs-reviews__label {
    float: left;
    line-height: 4rem; }
  .cs-reviews__item {
    padding: 0 0 2.5rem;
    margin: 0 0 2.5rem;
    border-bottom: 1px solid #dcdcdc;
    list-style: none; }
    .cs-reviews__item:last-of-type {
      border-bottom: 0;
      margin-bottom: 0; }
  .cs-reviews__form {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    margin: 2.5rem 0; }
    @media (min-width: 64em) {
      .cs-reviews__form {
        background: #f0f0f0;
        padding: 3rem; } }
    .cs-reviews__form .swatch-opt {
      margin-bottom: 1.5em;
      font-size: 1.4rem;
      width: 100%; }
      .cs-reviews__form .swatch-opt .swatch-attribute {
        flex-direction: column;
        align-items: flex-start !important; }
      .cs-reviews__form .swatch-opt .swatch-attribute-label {
        font-weight: 700;
        font-size: 0.85em;
        width: 100%;
        text-transform: none;
        color: inherit; }
        .cs-reviews__form .swatch-opt .swatch-attribute-label:after {
          content: ' *'; }
      .cs-reviews__form .swatch-opt .swatch-attribute-options {
        margin-bottom: 0; }
  .cs-reviews__headline {
    width: 100%;
    margin: 0 0 1.4em;
    text-align: left;
    font-size: 1.8rem; }
  .cs-reviews__product-name {
    font-weight: 200; }
  .cs-reviews__rating-item:not(:first-of-type) {
    margin-top: 1em; }
  .cs-reviews__options {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 1.5em;
    font-size: 1.4rem; }
    @media (min-width: 64em) {
      .cs-reviews__options {
        width: 50%;
        padding-right: 3rem;
        border-right: 1px solid #dcdcdc; } }
  .cs-reviews__ratings {
    width: 100%; }
  .cs-reviews__fieldset {
    width: 100%;
    margin-bottom: 1.5em;
    text-align: left;
    box-sizing: border-box; }
    @media (min-width: 64em) {
      .cs-reviews__fieldset {
        width: 50%;
        padding-left: 3rem; } }
  .cs-reviews__submit {
    width: 100%;
    display: flex;
    justify-content: flex-start; }
    @media (min-width: 64em) {
      .cs-reviews__submit {
        justify-content: center; } }
  .cs-reviews__submit-button {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5; }
    .cs-reviews__submit-button::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-reviews__submit-button, .cs-reviews__submit-button:visited {
      cursor: pointer;
      border-radius: 0px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #78b0e0; }
      .cs-reviews__submit-button *, .cs-reviews__submit-button:visited * {
        color: #fff;
        fill: #fff; }
    .cs-reviews__submit-button:hover:not([disabled]), .cs-reviews__submit-button:focus:not([disabled]), .cs-reviews__submit-button:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #346097; }
      .cs-reviews__submit-button:hover:not([disabled]) *, .cs-reviews__submit-button:focus:not([disabled]) *, .cs-reviews__submit-button:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .cs-reviews__submit-button[disabled] {
      opacity: 0.65;
      cursor: auto; }
    @media (max-width: 30em) {
      .cs-reviews__submit-button {
        width: 100%; } }
  .cs-reviews__rating-control {
    box-sizing: content-box;
    border: 1px solid #dcdcdc;
    border-radius: 0px;
    padding: 0.75em 1em;
    height: 1.275em;
    background: transparent;
    margin: 0 0 0.2em;
    display: flex;
    align-items: center; }
    .cs-reviews__rating-control input[type='radio'],
    .cs-reviews__rating-control .cs-reviews__rate-stars span {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      white-space: nowrap;
      /* 1 */ }
  .cs-reviews__rate-stars {
    box-sizing: border-box;
    order: 0; }
  .cs-reviews__rate-feedback {
    order: 1;
    font-size: 0.85em;
    line-height: 0.9em;
    color: #888;
    font-weight: 700;
    margin-left: 1.5rem;
    display: flex;
    align-items: center; }
  .cs-reviews__review-field {
    min-height: 10rem;
    resize: vertical; }
  .cs-reviews .cs-pagination__content {
    justify-content: center;
    margin: 2.5rem 0; }
  .cs-reviews--load-more {
    padding-bottom: 3rem; }
    @media (min-width: 48em) {
      .cs-reviews--load-more .cs-reviews__content {
        position: relative; } }
    .cs-reviews--load-more .cs-reviews__button {
      display: inline-block;
      font-size: 1.4rem;
      font-weight: 700;
      text-align: center;
      position: relative;
      line-height: 1.275em;
      margin: 0;
      text-transform: none;
      padding: 0.75em 1em;
      box-sizing: border-box;
      transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
      vertical-align: middle;
      z-index: 5;
      position: relative;
      margin: 2rem 0 0.5rem;
      padding-right: 4rem;
      position: relative; }
      .cs-reviews--load-more .cs-reviews__button::-moz-focus-inner {
        border: 0;
        padding: 0;
        margin: 0; }
      .cs-reviews--load-more .cs-reviews__button, .cs-reviews--load-more .cs-reviews__button:visited {
        cursor: pointer;
        border-radius: 0px;
        outline-offset: 1px;
        text-decoration: none;
        box-shadow: none;
        border: 1px solid transparent;
        color: #fff;
        background-color: #78b0e0; }
        .cs-reviews--load-more .cs-reviews__button *, .cs-reviews--load-more .cs-reviews__button:visited * {
          color: #fff;
          fill: #fff; }
      .cs-reviews--load-more .cs-reviews__button:hover:not([disabled]), .cs-reviews--load-more .cs-reviews__button:focus:not([disabled]), .cs-reviews--load-more .cs-reviews__button:active:not([disabled]) {
        box-shadow: none;
        outline: none;
        text-decoration: none;
        border: 1px solid transparent;
        color: #fff;
        background-color: #346097; }
        .cs-reviews--load-more .cs-reviews__button:hover:not([disabled]) *, .cs-reviews--load-more .cs-reviews__button:focus:not([disabled]) *, .cs-reviews--load-more .cs-reviews__button:active:not([disabled]) * {
          color: #fff;
          fill: #fff; }
      .cs-reviews--load-more .cs-reviews__button[disabled] {
        opacity: 0.65;
        cursor: auto; }
      .cs-reviews--load-more .cs-reviews__button:before, .cs-reviews--load-more .cs-reviews__button:after {
        content: '';
        position: absolute;
        top: 50%;
        width: 1rem;
        height: 1px;
        left: auto;
        background-color: #fff;
        transition: background-color 0.15s linear;
        backface-visibility: hidden; }
      .cs-reviews--load-more .cs-reviews__button:hover:before, .cs-reviews--load-more .cs-reviews__button:hover:after {
        background-color: #888; }
      .cs-reviews--load-more .cs-reviews__button[aria-expanded="true"]:before, .cs-reviews--load-more .cs-reviews__button[aria-expanded="true"]:after {
        background-color: #888; }
      .cs-reviews--load-more .cs-reviews__button:before {
        transform: rotate(45deg) translate3d(0, 0, 0);
        right: calc( 1.5rem + 1.2rem - 0.4rem); }
      .cs-reviews--load-more .cs-reviews__button:after {
        transform: rotate(-45deg) translate3d(0, 0, 0);
        right: 1.6rem; }
      .cs-reviews--load-more .cs-reviews__button:hover:after, .cs-reviews--load-more .cs-reviews__button:hover:before {
        color: #fff; }
      .cs-reviews--load-more .cs-reviews__button span {
        margin-top: 0;
        z-index: 1;
        font-weight: 700;
        vertical-align: baseline;
        display: inline-block;
        line-height: 1.275em;
        letter-spacing: normal; }
      .cs-reviews--load-more .cs-reviews__button .count {
        display: inline-flex;
        padding-left: 0.5rem; }
        .cs-reviews--load-more .cs-reviews__button .count:before {
          content: '('; }
        .cs-reviews--load-more .cs-reviews__button .count:after {
          content: ')'; }
      .cs-reviews--load-more .cs-reviews__button .loading-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url("../images/spinner.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 2.5rem 2.5rem; }
        .cs-reviews--load-more .cs-reviews__button .loading-mask img {
          display: none; }
    .cs-reviews--load-more .cs-reviews__count {
      color: #696969; }
