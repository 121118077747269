/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/***
 * Remove browser's margins, paddings and list style rules
 */
.cs-stack-nav__trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: #f0f0f0;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 700; }
  @media (min-width: 48em) {
    .cs-stack-nav__trigger {
      display: none; } }
  .cs-stack-nav__trigger-icon {
    margin-left: 1.5rem;
    width: 6px;
    height: 10px;
    fill: #888;
    transform: rotate(90deg);
    transition: transform 0.3s; }
  .cs-stack-nav__trigger[aria-expanded='true'] .cs-stack-nav__trigger-icon {
    transform: rotate(-90deg); }

@media (max-width: 47.99em) {
  .cs-stack-nav__content {
    background-color: #f0f0f0; } }

@media (min-width: 48em) {
  .cs-stack-nav__content {
    border-radius: 0px;
    background-color: #fff;
    box-shadow: 1px 3px 3rem 0 rgba(72, 72, 72, 0.28);
    padding: 2rem 0; } }

.cs-stack-nav__list {
  margin: 0;
  padding: 0;
  list-style: none; }
  .cs-stack-nav__list > svg,
  .cs-stack-nav__list > img {
    display: none; }

.cs-stack-nav__nav .cs-stack-nav__list li {
  margin: 0;
  overflow: hidden;
  white-space: nowrap; }

.cs-stack-nav__nav .cs-stack-nav__list li a,
.cs-stack-nav__nav .cs-stack-nav__list li strong {
  font-size: 1.4rem;
  font-weight: 300;
  color: #696969;
  border-left: none;
  padding: 0.75rem 1.5rem;
  display: block;
  margin-bottom: 0.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media (min-width: 48em) {
    .cs-stack-nav__nav .cs-stack-nav__list li a,
    .cs-stack-nav__nav .cs-stack-nav__list li strong {
      padding: 0.5rem 1.8rem 0.5rem 2.5rem; } }
  .cs-stack-nav__nav .cs-stack-nav__list li a svg,
  .cs-stack-nav__nav .cs-stack-nav__list li a img,
  .cs-stack-nav__nav .cs-stack-nav__list li strong svg,
  .cs-stack-nav__nav .cs-stack-nav__list li strong img {
    width: 2rem;
    height: 1.4rem;
    margin-left: 0.3rem;
    transform: translateY(0.1rem); }

.cs-stack-nav__nav .cs-stack-nav__list li a:hover {
  background-color: #dcdcdc;
  color: #484848; }
  @media (min-width: 48em) {
    .cs-stack-nav__nav .cs-stack-nav__list li a:hover {
      background-color: #f0f0f0; } }

.cs-stack-nav__nav .cs-stack-nav__list li.current strong {
  position: relative;
  border-left: none;
  border-color: transparent;
  font-weight: 700;
  color: #484848; }
  .cs-stack-nav__nav .cs-stack-nav__list li.current strong:hover {
    color: #484848; }
  .cs-stack-nav__nav .cs-stack-nav__list li.current strong:before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    display: block;
    width: 0.5rem;
    height: 100%;
    background: #FFEF45; }

.cs-stack-nav__nav .item .delimiter {
  border-top: 1px solid #dcdcdc;
  display: block;
  margin: 1rem 1.8rem; }

.cs-stack-nav__nav .cs-stack-nav__list li.current a {
  padding: 0;
  margin-bottom: 0; }

@media (min-width: 48em) {
  .cs-stack-nav__nav {
    display: block;
    position: relative; } }
