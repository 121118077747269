/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
.cs-sticky-block {
  box-sizing: border-box; }
  @media (min-width: 64em) {
    .cs-sticky-block {
      position: sticky;
      z-index: 10;
      top: 1em; } }
  @media (min-width: 48em) {
    .cs-sticky-block--pdp {
      position: relative;
      z-index: 3;
      margin-right: calc(15px - 0.5rem); }
      .page-product-bundle .cs-sticky-block--pdp {
        margin-right: 0; } }
  @media (min-width: 64em) {
    .cs-sticky-block--pdp {
      top: 3rem;
      margin-right: 15px; }
      .page-product-bundle .cs-sticky-block--pdp {
        margin-right: 0; } }
  @media screen and (min-width: 1230px) {
    .cs-sticky-block--pdp {
      margin-right: 0; } }
  @media (min-width: 48em) {
    .cs-sticky-block--no-mobile {
      position: sticky;
      top: 8.5rem; } }
  @media (min-width: 64em) {
    .cs-sticky-block--no-mobile {
      top: 1.5rem; } }
