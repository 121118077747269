@import 'config/variables';
@import 'components/field/mixin';
@import 'components/button/mixin';
@import 'vendors/include-media';
@import 'utils/visually-hidden';
@import 'components/pseudoicon/mixin';

$reviews-border: 1px solid $color_border-500 !default;
$reviews-spacing: 2.5rem !default;

// Reviews form styling
$reviews_form-padding-desktop: 3rem !default;
$reviews_form-two-columns-desktop: true !default;
$reviews_form-two-columns-spacing: $reviews_form-padding-desktop !default;
$reviews_form-background-desktop: $color_background-500 !default;

$reviews_form-button-align: flex-start !default;
$reviews_form-button-align-desktop: center !default;
$reviews_form-headline-font-size: $font-size_headline-3 !default;
$reviews_form-product-name-font-weight: 200 !default;
$reviews_form-rating-control-padding: $field_input-padding !default;
$reviews_form-rating-control-content-height: $field_input-line-height !default;
$reviews_form-rating-control-border: $field_input-border !default;
$reviews_form-rating-control-background: transparent !default;
$reviews_form-fedback-text-color: $color_text-400 !default;
$reviews_form-fedback-font-size: 0.85em !default;

$reviews_form-submit-button-type: $button_default-type !default;
$reviews_form-submit-button-icon-pos: 'no-icon' !default;

$reviews_load-more-scenario-enabled: true !default;
$reviews_load-more-button-type: $button_default-type !default;
$reviews_load-more-button-icon-pos: 'no-icon' !default;
$reviews_load-more-pseudoicon-enabled: true !default;
$reviews_count-color: $color_text-500 !default;

.#{$ns}reviews {
    $root: &;

    flex: 1 1 0;

    @include media('<laptop') {
        border-bottom: $reviews-border;
    }

    &__title {
        background: $color_gallery;

        @include media('>=tablet') {
            background: none;
            padding: 0;
        }
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__label {
        float: left;
        line-height: 4rem;
    }

    &__item {
        padding: 0 0 $reviews-spacing;
        margin: 0 0 $reviews-spacing;
        border-bottom: $reviews-border;
        list-style: none;

        &:last-of-type {
            border-bottom: 0;
            margin-bottom: 0;
        }
    }

    &__form {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        margin: $reviews-spacing 0;

        @include media('>=laptop') {
            background: $reviews_form-background-desktop;
            padding: $reviews_form-padding-desktop;
        }

        // Styles of varinant select for review (displayed if enabled in configuration)
        .swatch-opt {
            margin-bottom: 1.5em;
            font-size: $field_font-size;
            width: 100%;

            .swatch-attribute {
                flex-direction: column;
                align-items: flex-start !important;
            }

            .swatch-attribute-label {
                @include field-label();

                &:after {
                    content: ' *';
                }
            }

            .swatch-attribute-options {
                margin-bottom: 0;
            }
        }
    }

    &__headline {
        width: 100%;
        margin: 0 0 1.4em;
        text-align: left;
        font-size: $reviews_form-headline-font-size;
    }

    &__product-name {
        font-weight: $reviews_form-product-name-font-weight;
    }

    &__rating-item:not(:first-of-type) {
        margin-top: 1em;
    }

    &__options {
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 1.5em;
        font-size: $field_font-size;

        @if ($reviews_form-two-columns-desktop) {
            @include media('>=laptop') {
                width: 50%;
                padding-right: $reviews_form-two-columns-spacing;
                border-right: $reviews-border;
            }
        }
    }

    &__ratings {
        width: 100%;
    }

    &__fieldset {
        width: 100%;
        margin-bottom: 1.5em;
        text-align: left;
        box-sizing: border-box;

        @if ($reviews_form-two-columns-desktop) {
            @include media('>=laptop') {
                width: 50%;
                padding-left: $reviews_form-two-columns-spacing;
            }
        }
    }

    &__submit {
        width: 100%;
        display: flex;
        justify-content: $reviews_form-button-align;

        @include media('>=laptop') {
            justify-content: $reviews_form-button-align-desktop;
        }
    }

    &__submit-button {
        @include button(
            $type: $reviews_form-submit-button-type,
            $icon_pos: $reviews_form-submit-button-icon-pos
        );

        @include media('<=phoneLg') {
            width: 100%;
        }
    }

    &__rating-control {
        box-sizing: content-box;
        border: $reviews_form-rating-control-border;
        border-radius: $border-radius_base;
        padding: $reviews_form-rating-control-padding;
        height: $reviews_form-rating-control-content-height;
        background: $reviews_form-rating-control-background;
        margin: 0 0 0.2em;
        display: flex;
        align-items: center;

        input[type='radio'],
        #{$root}__rate-stars span {
            @include visually-hidden();
        }
    }

    &__rate-stars {
        box-sizing: border-box;
        order: 0;
    }

    &__rate-feedback {
        order: 1;
        font-size: $reviews_form-fedback-font-size;
        line-height: 0.9em;
        color: $reviews_form-fedback-text-color;
        font-weight: 700;
        margin-left: 1.5rem;
        display: flex;
        align-items: center;
    }

    &__review-field {
        min-height: 10rem;
        resize: vertical;
    }

    .#{$ns}pagination__content {
        justify-content: center;
        margin: $reviews-spacing 0;
    }

    @if ($reviews_load-more-scenario-enabled) {
        &--load-more {
            padding-bottom: 3rem;

            #{$root}__content {
                @include media('>=tablet') {
                    position: relative;
                }
            }

            #{$root}__button {
                @include button(
                    $type: $reviews_load-more-button-type,
                    $icon_pos: $reviews_load-more-button-icon-pos
                );

                @if ($reviews_load-more-pseudoicon-enabled) {
                    @include pseudoicon(
                        $type: 'arrow',
                        $color: $button_primary-fill,
                        $width: 1rem,
                        $height: 1px,
                        $arrow-default: 'down',
                        $position-side-offset: 1.6rem
                    );

                    margin: 2rem 0 0.5rem;
                    padding-right: 4rem;
                    position: relative;

                    &:hover {
                        &:after,
                        &:before {
                            color: $button_primary--hover-fill;
                        }
                    }
                }

                span {
                    @include button-span(
                        $type: $reviews_load-more-button-type,
                        $icon_pos: $reviews_load-more-button-icon-pos
                    );
                }

                .count {
                    display: inline-flex;
                    padding-left: 0.5rem;

                    &:before {
                        content: '(';
                    }

                    &:after {
                        content: ')';
                    }
                }

                .loading-mask {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-image: url('../images/spinner.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 2.5rem 2.5rem;

                    img {
                        display: none;
                    }
                }
            }

            #{$root}__count {
                color: $reviews_count-color;
            }
        }
    }
}
