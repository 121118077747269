/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
.login-container .block-new-customer .actions-toolbar {
  margin-top: 2.5rem; }

.login-container .block .block-title {
  font-size: 1.8rem;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 1.5rem;
  padding-bottom: 1.2rem; }

.login-container .fieldset:after {
  margin: 1rem 0 0;
  content: attr(data-hasrequired);
  display: block;
  letter-spacing: normal;
  word-spacing: normal;
  color: #e02b27;
  font-size: 1.2rem; }

.block-addresses-list .items.addresses > .item {
  margin-bottom: 2rem; }

.block-addresses-list .items.addresses > .item:last-child {
  margin-bottom: 0; }

.form-address-edit .region_id {
  display: none; }

.form-address-edit .actions-toolbar .action.primary {
  font-size: 1.8rem; }

.form-edit-account .fieldset.password {
  display: none; }

.box-billing-address .box-content,
.box-shipping-address .box-content,
.box-information .box-content,
.box-newsletter .box-content {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  line-height: 2.6rem; }

.field.password .control {
  display: flex;
  flex-direction: column; }

.field.password .control .mage-error {
  order: 2; }

.field.password .control .input-text {
  order: 0;
  z-index: 2; }

.fieldset .fullname.field > .label {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  /* 1 */ }
  .fieldset .fullname.field > .label + .control {
    width: 100%; }

.field.street .field .label {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  /* 1 */ }

.control.captcha-image {
  margin-top: 1rem; }

.control.captcha-image .captcha-img {
  vertical-align: middle; }

@media only screen and (max-width: 639px) {
  .account .column.main,
  .account .sidebar-additional {
    margin: 0; } }

@media (max-width: 47.99em) {
  .login-container .fieldset:after {
    text-align: center; }
  .control.captcha-image .captcha-img {
    margin-bottom: 1rem;
    display: block; }
  .form.search.advanced .field.price .with-addon .input-text {
    flex-basis: auto;
    width: 100%; } }

@media (min-width: 48em) {
  .login-container .block .login .actions-toolbar > .primary {
    margin-bottom: 0;
    margin-right: 3rem; }
  .login-container .block .login .actions-toolbar > .secondary {
    float: left; }
  .fieldset .fullname .field .label {
    padding: 6px 15px 0 0;
    text-align: right;
    width: 25.8%;
    box-sizing: border-box;
    float: left; }
  .fieldset .fullname .field .control {
    width: 74.2%;
    float: left; }
  .block-addresses-list .items.addresses {
    font-size: 0; }
  .block-addresses-list .items.addresses > .item {
    display: inline-block;
    font-size: 1.4rem;
    margin-bottom: 2rem;
    vertical-align: top;
    width: 48.8%; }
  .block-addresses-list .items.addresses > .item:nth-last-child(1),
  .block-addresses-list .items.addresses > .item:nth-last-child(2) {
    margin-bottom: 0; }
  .block-addresses-list .items.addresses > .item:nth-child(even) {
    margin-left: 2.4%; }
  .control.captcha-image .captcha-img {
    margin: 0 1rem 1rem 0; }
  .dashboard-welcome-toggler {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    /* 1 */ } }
