/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
.cs-review {
  font-size: 1.4rem; }
  .cs-review__title {
    font-weight: 700;
    margin: 0 0 0.7rem;
    color: #484848; }
  .cs-review__rating, .cs-review__ratings {
    margin: 0 0 2rem;
    padding: 0; }
  .cs-review__compound-rating {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    max-width: 20rem;
    margin-bottom: 0.3em; }
    .cs-review__compound-rating:last-child {
      margin-bottom: 0; }
  .cs-review__meta {
    margin: 0 0 2rem;
    font-size: 0.9em; }
    .cs-review__meta--variant-support {
      display: flex;
      align-items: center; }
  .cs-review__meta-item {
    vertical-align: middle;
    padding: 0 1rem;
    line-height: 1em;
    border-right: 1px solid #dcdcdc; }
    .cs-review__meta-item:first-child {
      padding-left: 0; }
    .cs-review__meta-item:last-child {
      border-right: none; }
  .cs-review__author {
    color: #484848; }
  .cs-review__date {
    margin-left: -4px;
    color: #888; }
  .cs-review__variant {
    flex-grow: 1;
    padding: 0 1rem; }
    .cs-review__variant .swatch-attribute-options {
      margin-bottom: 0; }
    .cs-review__variant .swatch-option {
      cursor: default; }
  .cs-review__content {
    margin: 0;
    color: #888; }
