/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* Radio and checkbox styling */
.cs-captcha__image-controls {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 1.5rem; }
  .cs-dashboard .cs-captcha__image-controls {
    flex-direction: column;
    align-items: flex-start; }
    @media (min-width: 30em) {
      .cs-dashboard .cs-captcha__image-controls {
        flex-direction: row;
        align-items: flex-end; } }
  @media (max-width: 48em) {
    .cs-captcha__image-controls {
      flex-wrap: wrap; } }

.cs-captcha__image {
  margin-right: 1rem; }

.cs-captcha__button {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  position: relative;
  line-height: 1.275em;
  margin: 0;
  text-transform: none;
  padding: 0.75em 1em;
  box-sizing: border-box;
  transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
  vertical-align: middle;
  z-index: 5; }
  .cs-captcha__button::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0; }
  .cs-captcha__button, .cs-captcha__button:visited {
    cursor: pointer;
    border-radius: 0px;
    outline-offset: 1px;
    text-decoration: none;
    box-shadow: none;
    border: 1px solid #78b0e0;
    color: #78b0e0;
    background-color: #fff; }
    .cs-captcha__button *, .cs-captcha__button:visited * {
      color: #78b0e0;
      fill: #78b0e0; }
  .cs-captcha__button:hover:not([disabled]), .cs-captcha__button:focus:not([disabled]), .cs-captcha__button:active:not([disabled]) {
    box-shadow: none;
    outline: none;
    text-decoration: none;
    border: 1px solid #346097;
    color: #fff;
    background-color: #346097; }
    .cs-captcha__button:hover:not([disabled]) *, .cs-captcha__button:focus:not([disabled]) *, .cs-captcha__button:active:not([disabled]) * {
      color: #fff;
      fill: #fff; }
  .cs-captcha__button[disabled] {
    opacity: 0.65;
    cursor: auto; }
  .cs-dashboard .cs-captcha__button {
    margin-top: 2rem; }
    @media (min-width: 30em) {
      .cs-dashboard .cs-captcha__button {
        margin-top: 0; } }

.cs-captcha__button-span {
  margin-top: 0;
  z-index: 1;
  font-weight: 700;
  vertical-align: baseline;
  display: inline-block;
  line-height: 1.275em;
  letter-spacing: normal; }

.cs-captcha__note {
  display: block;
  font-size: 0.85em;
  padding: 0.3em 0 0;
  color: #696969; }

.cs-dashboard .cs-captcha__wrapper {
  width: 100%;
  margin-bottom: 2rem; }
