@import 'config/variables';

$review_font-size: 1.4rem !default;
$review_title-font-weight: 700 !default;
$review_title-color: $color_text-800 !default;
$review_author-color: $color_text-800 !default;
$review_content-color: $color_text-400 !default;
$review_date-color: $color_text-400 !default;
$review_meta-item-font-size: 0.9em !default;
$review_meta-item-border: 1px solid $color_border-500 !default;
$review_meta-item-padding: 0 1rem !default;
$review_hide-compound-ratings: false !default;

.#{$ns}review {
    font-size: $review_font-size;

    &__title {
        font-weight: $review_title-font-weight;
        margin: 0 0 0.7rem;
        color: $review_title-color;
    }

    &__rating,
    &__ratings {
        margin: 0 0 2rem;
        padding: 0;
    }

    &__compound-rating {
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        max-width: 20rem;
        margin-bottom: 0.3em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @if ($review_hide-compound-ratings) {
        &__compound-rating {
            display: none;

            & .cs-star-rating__label {
                display: none;
            }
        }

        &__overall-rating {
            display: flex;
        }
    }

    &__meta {
        margin: 0 0 2rem;
        font-size: $review_meta-item-font-size;

        &--variant-support {
            display: flex;
            align-items: center;
        }
    }

    &__meta-item {
        vertical-align: middle;
        padding: $review_meta-item-padding;
        line-height: 1em;
        border-right: $review_meta-item-border;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            border-right: none;
        }
    }

    &__author {
        color: $review_author-color;
    }

    &__date {
        margin-left: -4px;
        color: $review_date-color;
    }

    &__variant {
        flex-grow: 1;
        padding: $review_meta-item-padding;

        .swatch-attribute-options {
            margin-bottom: 0;
        }

        .swatch-option {
            cursor: default;
        }
    }

    &__content {
        margin: 0;
        color: $review_content-color;
    }
}
