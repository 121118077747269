/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
.ui-datepicker {
  display: none;
  z-index: 999999 !important;
  border: none;
  border-radius: 0px;
  padding: 0;
  width: auto;
  min-width: 25rem;
  box-shadow: 1px 3px 3rem 0 rgba(72, 72, 72, 0.28);
  background: #f0f0f0; }

.ui-datepicker-header {
  position: relative;
  background: #FFEF45;
  padding: 1.2rem 2rem;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px; }

.ui-datepicker-prev,
.ui-datepicker-next {
  position: absolute;
  top: 2rem;
  width: 1.8em;
  height: 1.8em;
  cursor: pointer; }
  .ui-datepicker-prev span,
  .ui-datepicker-next span {
    display: block;
    position: absolute;
    left: 50%;
    top: 0.2rem;
    font-size: 0px;
    width: 0;
    height: 0; }

.ui-datepicker-prev {
  left: 1.5rem; }
  .ui-datepicker-prev span {
    border-width: 0;
    position: relative; }
    .ui-datepicker-prev span:before, .ui-datepicker-prev span:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 2rem;
      width: 0;
      height: 0; }
    .ui-datepicker-prev span:before {
      left: -1.2rem;
      border-top: 0.8rem solid transparent;
      border-bottom: 0.8rem solid transparent;
      border-right: 0.8rem solid #78b0e0;
      margin-top: -0.8rem; }
    .ui-datepicker-prev span:after {
      left: -1rem;
      border-top: 0.6rem solid transparent;
      border-bottom: 0.6rem solid transparent;
      border-right: 0.6rem solid #FFEF45;
      margin-top: -0.6rem; }

.ui-datepicker-next {
  right: 1.5rem; }
  .ui-datepicker-next span {
    border-width: 0;
    position: relative; }
    .ui-datepicker-next span:before, .ui-datepicker-next span:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 0;
      height: 0; }
    .ui-datepicker-next span:before {
      right: -1.2rem;
      border-top: 0.8rem solid transparent;
      border-bottom: 0.8rem solid transparent;
      border-left: 0.8rem solid #78b0e0;
      margin-top: -0.8rem; }
    .ui-datepicker-next span:after {
      right: -1rem;
      border-top: 0.6rem solid transparent;
      border-bottom: 0.6rem solid transparent;
      border-left: 0.6rem solid #FFEF45;
      margin-top: -0.6rem; }

.ui-datepicker-title {
  text-align: center;
  margin: 0 1.5rem; }

.ui-datepicker-title select {
  display: inline-block;
  font-size: 1.4rem; }

select.ui-datepicker-month-year {
  width: 100%; }

.ui-datepicker-month {
  width: 47%;
  margin-right: 6%; }

.ui-datepicker-year {
  width: 47%; }

.ui-datepicker-table {
  border: none;
  font-size: 1rem;
  border-collapse: collapse; }

.ui-datepicker-calendar {
  border-collapse: collapse;
  margin: 1rem 1.5rem 1.5rem;
  border-width: 0;
  width: 23rem; }
  .ui-datepicker-calendar td,
  .ui-datepicker-calendar th {
    background: none;
    border-width: 0;
    width: 3rem;
    height: 3rem;
    padding: 0;
    text-align: center; }
  .ui-datepicker-calendar th {
    text-transform: uppercase;
    color: #484848; }
  .ui-datepicker-calendar .ui-datepicker-week-col {
    text-align: center; }
  .ui-datepicker-calendar td a,
  .ui-datepicker-calendar th span,
  .ui-datepicker-calendar td span {
    background: none;
    border-width: 0;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    text-align: center;
    line-height: 3rem;
    padding: 0;
    font-size: 1.4rem;
    display: block; }
  .ui-datepicker-calendar td a,
  .ui-datepicker-calendar td span {
    color: #696969; }
  .ui-datepicker-calendar .ui-state-hover {
    background: #78b0e0;
    color: #fff; }
  .ui-datepicker-calendar .ui-state-disabled {
    background: transparent; }
    .ui-datepicker-calendar .ui-state-disabled span {
      color: #dcdcdc; }
  .ui-datepicker-calendar .ui-state-active {
    background: #78b0e0;
    color: #fff; }

.ui-datepicker-buttonpane {
  overflow: hidden;
  padding-top: 1rem;
  border-top: #dcdcdc 1px solid; }
  .ui-datepicker-buttonpane button {
    float: right; }
  .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: left; }

.ui-datepicker-rtl {
  direction: rtl; }

.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto; }

.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto; }

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto; }

.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto; }

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: right; }

.ui-datepicker-rtl .ui-datepicker-group {
  float: right; }

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px; }

.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px; }

.ui-timepicker-div {
  padding: 1rem 0 0.5rem 0; }
  .ui-timepicker-div .ui-widget-header {
    margin-bottom: 0.8rem; }
  .ui-timepicker-div dl {
    text-align: left; }
    .ui-timepicker-div dl dt {
      height: 2.5rem;
      margin-bottom: -2.2rem; }
    .ui-timepicker-div dl .ui_tpicker_time_label {
      margin-bottom: -2.5rem; }
    .ui-timepicker-div dl dd {
      margin: 0 0 1rem 6.5rem; }
  .ui-timepicker-div td {
    font-size: 90%; }

.ui-tpicker-grid-label {
  background: none;
  border: none;
  margin: 0;
  padding: 0; }

.ui-slider {
  position: relative;
  text-align: left; }

.ui-slider-handle {
  height: 1rem;
  width: 1rem;
  border-radius: 1rem;
  background: #78b0e0;
  display: block;
  position: absolute;
  z-index: 2;
  cursor: default; }

.ui-slider-horizontal {
  height: 1rem;
  border-radius: 1rem;
  border: none;
  background: #f0f0f0; }
  .ui-slider-horizontal .ui-slider-handle {
    margin-left: -0.5rem; }

/* IE6 IFRAME FIX (taken from datepicker 1.5.3 */
.ui-datepicker-cover {
  position: absolute;
  /*must have*/
  z-index: -1;
  /*must have*/
  filter: mask();
  /*must have*/
  top: -4px;
  /*must have*/
  left: -4px;
  /*must have*/
  width: 200px;
  /*must have*/
  height: 200px;
  /*must have*/ }
